
import { defineComponent, reactive, onMounted, inject } from "vue";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { ResponseList, Conversations } from "../../types";
import { TableState } from "ant-design-vue/lib/table/interface";
import { SorterType } from "../../types/vue-antd";
import { visitTypesIconMap } from "../utils/const";
import { useRouter, useRoute } from "vue-router";
import { AxiosStatic } from "axios";

interface Table {
  columns: TableState["columns"];
  sorter: SorterType;
  data: Array<Conversations>;
  pagination: Omit<TableState["pagination"], "current,total,pageSize"> & {
    current: number;
    total: number;
    pageSize: number;
  };
  loading: boolean;
}

export const ConversationsComponent = defineComponent({
  setup() {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const { showErrors } = useShowErrors();
    const router = useRouter();
    const route = useRoute();

    const page: number = route.query.page ? Number(route.query.page) : 1;
    const pageSize: number = route.query.pageSize
      ? Number(route.query.pageSize)
      : 10;

    const table: Table = reactive({
      data: [],
      columns: [
        {
          title: t("CONVERSATIONS.TABLE.PATIENT_FULL_NAME"),
          dataIndex: "patientFullName",
          sorter: false,
          slots: { customRender: "patientFullName" },
        },
        {
          title: t("CONVERSATIONS.TABLE.VISIT_DATE"),
          dataIndex: "visitDate",
          sorter: false,
          slots: { customRender: "visitDate" },
        },
        {
          title: t("CONVERSATIONS.TABLE.QUESTION_DATE"),
          dataIndex: "questionDate",
          sorter: false,
          slots: { customRender: "questionDate" },
        },
        {
          dataIndex: "actions",
          sorter: false,
          slots: { customRender: "actions" },
        },
      ],
      sorter: {},
      loading: true,
      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "25", "50"],
        buildOptionText: ({ value }: { value: string }) => {
          return value;
        },
        showTotal: (total: number, range: Array<number>) =>
          t("PAGINATION", {
            total,
            rangeFrom: range[0],
            rangeTo: range[1],
          }),
        current: page,
        pageSize: pageSize,
        total: 0,
      },
    });

    const startConversation = async (conversationId: Conversations) => {
      router.push({ path: `/conversation/${conversationId}` });
    };

    const getVisits = async (pagination: TableState["pagination"]) => {
      table.loading = true;
      const link = `v1/conversations?page=${
        pagination?.current ? pagination?.current - 1 : 0
      }&size=${pagination?.pageSize}`;

      try {
        const visitsRes = await http
          .get(link)
          .then((res): ResponseList<Conversations> => res.data);
        table.data = visitsRes.content;
        table.pagination = {
          current: visitsRes.pageable.pageNumber + 1,
          total: visitsRes.totalElements,
          pageSize: visitsRes.size,
        };
      } catch (err: any) {
        showErrors(err.response?.data);
      } finally {
        table.loading = false;
      }
    };

    onMounted(() => {
      getVisits(table.pagination);
    });

    const handleTableChange = (pagination: TableState["pagination"]) => {
      router.replace(
        `${route.path}?page=${pagination?.current}&pageSize=${pagination?.pageSize}`
      );
      getVisits(pagination);
    };

    return {
      t,
      table,
      handleTableChange,
      startConversation,
      visitTypesIconMap,
      page,
      pageSize,
      moment,
    };
  },
});
export default ConversationsComponent;
