import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bdbb56f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "conversations" }
const _hoisted_2 = { class: "conversations-table__answer-question" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.t("CONVERSATIONS.HEADER")), 1 /* TEXT */),
    _createVNode(_component_a_table, {
      columns: _ctx.table.columns,
      class: "conversations-table",
      "row-key": (record) => record.conversationId,
      rowClassName: 
        (record) =>
          record.status
            ? `conversations-table__row conversations-table__row--${record.status
                .toLowerCase()
                .replaceAll('_', '-')}`
            : `conversations-table__row conversations-table__row--no-status`
      ,
      onChange: _ctx.handleTableChange,
      "data-source": _ctx.table.data,
      loading: _ctx.table.loading,
      pagination: _ctx.table.pagination,
      locale: { emptyText: _ctx.t('NO_DATA') }
    }, {
      patientFullName: _withCtx(({ record }) => [
        _createElementVNode("strong", null, _toDisplayString(record.patientFullName), 1 /* TEXT */)
      ]),
      visitDate: _withCtx(({ record }) => [
        _createElementVNode("span", null, _toDisplayString(_ctx.moment(record.visitDate).format("DD.MM.YYYY")), 1 /* TEXT */)
      ]),
      questionDate: _withCtx(({ record }) => [
        _createElementVNode("span", null, _toDisplayString(_ctx.moment(record.questionDate).format("DD.MM.YYYY")), 1 /* TEXT */)
      ]),
      actions: _withCtx(({ record }) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_button, {
            type: "tertiary",
            onClick: ($event: any) => (_ctx.startConversation(record.conversationId)),
            shape: "round"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.status & (record.status == "UNANSWERED")
                ? _ctx.t("CONVERSATIONS.TABLE.ANSWER_QUESTION")
                : _ctx.t("CONVERSATIONS.TABLE.BROWSE")), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["columns", "row-key", "rowClassName", "onChange", "data-source", "loading", "pagination", "locale"])
  ]))
}